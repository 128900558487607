<template>
  <div>
    <validation-observer ref="formRules">
      <!-- head -->
      <div>
        <b-card>
          <b-card-header>
            <b-row
              no-gutters
              class="w-100"
            >
              <b-col md="12">
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-popover.hover.focus="
                        '⚪️ La fiche de renseignement à l\'entrée des frontières terrestres est éditable par les agents aux postes frontaliers. ⚪️ Elle permet de collecter les données sur les voyageurs et leur séjours.'
                      "
                      size="lg"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      title="Information"
                    >
                      <feather-icon icon="BellIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
              <b-col md="2">
                <b-card-img
                  :src="require('@/assets/images/illustration/ppp20.jpg')"
                  alt="Image"
                  class="rounded-0 my-2"
                />
              </b-col>
              <b-col
                md="10"
                class="d-flex justify-content-center align-items-center px-3"
              >

                <div>
                  <h4 class="m-auto text-center">
                    FICHE DE RENSEIGNEMENT AUX FRONTIERES TERRESTRES (ENTREE)
                  </h4>
                  <br>

                </div>
              </b-col>
              <h5 class="d-flex justify-content-end mt-2">
                Tout les champs avec la mention<RequiredStarComponent /> sont requis</h5>
            </b-row>
          </b-card-header>
          <!-- body -->
          <b-col
            lg="12"
            class="pb-2"
          >
            <div>
              <b-card>
                <b-card-body>
                  <!-- <b-row class="mb-1">
                      <b-col md="12">
                        <b-form-group>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <div class="label">
                              Commissariat de police
                              <small>(Réservé à l’agent de police)</small>
                            </div>
                            <b-form-input
                              v-model="form.reserveAdministration.commisariat"
                              type="text"
                              placeholder="Commissariat de police de ILLACKONDJI par exemple"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row> -->

                  <b-row>
                    <!-- Date arrivee -->
                    <b-col md="4">
                      <b-form-group>
                        <label
                          for=""
                          class="col-form-label col-form-label-md"
                        >Date d'entrée du voyageur  <RequiredStarComponent class="mr-2" /></label>

                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|beforeOrToday"
                        >
                          <b-form-input
                            v-model="form.date"
                            lang="fr-CA"
                            type="date"
                            placeholder="Veuillez saisir la date d’entrée du visiteur"
                            class="form-control"
                            :max="today"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- heure -->
                    <b-col md="4">
                      <b-form-group>
                        <label
                          for=""
                          class="col-form-label col-form-label-md"
                        >Heure</label>
                        <flat-pickr
                          v-model="form.heure"
                          class="form-control"
                          placeholder="Veuillez saisir l’heure d’entrée du visiteur"
                          :config="{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                            minuteIncrement:1,
                          }"
                         
                        />
                        <!-- <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider> -->
                      </b-form-group>
                    </b-col>
                    <!-- npi -->
                    <b-col md="4">
                      <b-form-group>
                        <label
                          for=""
                          class="col-form-label col-form-label-md"
                        >NPI (Réservé aux Béninois)</label>
                        <b-form-input
                          v-model.number="form.npi"
                          v-digits-only="{ length: 10 }"
                          type="number"
                          placeholder="Veuillez entrer votre NPI"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <!-- lastname -->
                    <b-col md="6">
                      <b-form-group
                        label-cols-md="12"
                        label="Nom"
                      >
                        <b-form-input
                          v-model="form.lastname"
                          v-name
                          type="text"
                          placeholder="Veuillez entrer votre nom"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- firstname -->
                    <b-col md="6">
                      <b-form-group
                        label-cols-lg="12"
                        label="Prénom(s)"
                      >
                        <b-form-input
                          v-model="form.firstname"
                          v-name
                          type="text"
                          placeholder="Veuillez entrer votre prénom"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- sexe -->
                    <b-col lg="4">
                      <b-form-group
                        label-cols-lg="12"
                        label="Sexe"
                      >
                        <!-- <b-form-input
                          v-model="form.sexe"
                          v-name
                          type="text"
                          placeholder="Veuillez entrer votre prénom"
                        /> -->
                        <b-form-radio-group
                          v-model="form.sexe"
                          :options="sexeList"
                          class="ml-1"
                          value-field="value"
                          text-field="text"
                          disabled-field="disabled"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- maidenname -->
                    <b-col
                      v-if="form.sexe === 'Féminin'"
                      md="4"
                    >
                      <b-form-group>
                        <label
                          for=""
                          class="col-form-label col-form-label-md"
                        >Nom de jeune fille<RequiredStarComponent class="mr-2" /></label>
                        <b-form-input
                          v-model="form.maidenname"
                          type="text"
                          placeholder="Veuillez entrer le nom de la jeune fille"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- date naissance -->
                    <b-col lg="4">
                      <b-form-group>
                        <label
                          for=""
                          class="col-form-label col-form-label-md"
                        >Date de naissance<RequiredStarComponent class="mr-2" /></label>

                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|beforeOrToday|valideBirthdate"
                        >
                          <b-form-input
                            v-model="form.dateNaissance"
                            lang="fr-CA"
                            type="date"
                            placeholder="Veuillez saisir la date de naissance"
                            class="form-control"
                            :max="today"
                          />

                          <small class="text-danger">
                            {{ errors[0] }}
                          </small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- lieu naissance -->
                    <b-col md="4">
                      <b-form-group>
                        <label
                          for=""
                          class="col-form-label col-form-label-md"
                        >Lieu de naissance<RequiredStarComponent class="mr-2" /></label>

                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            v-model="form.lieuNaissance"
                            type="text"
                            placeholder="Veuillez entrer votre lieu de naissance"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <hr>
                    <!-- Nationalite -->
                    <b-col md="6">
                      <b-form-group>
                        <label
                          for=""
                          class="col-form-label col-form-label-md"
                        >Nationalité<RequiredStarComponent class="mr-2" /></label>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <SelectCountriesList
                            v-model="form.nationalite"
                            :input-value="form.nationalite"
                            :placeholder="'Sélectionnez le pays de votre  nationalité'"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- pays residence -->
                    <b-col md="6">
                      <b-form-group>
                        <label
                          for=""
                          class="col-form-label col-form-label-md"
                        >Pays de résidence<RequiredStarComponent class="mr-2" /></label>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <SelectCountriesList
                            v-model="form.paysResidence"
                            :input-value="form.paysResidence"
                            :placeholder="'Sélectionnez le pays de résidence'"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- adresse etranger -->
                    <b-col md="6">
                      <b-form-group>
                        <label
                          for=""
                          class="col-form-label col-form-label-md"
                        >Adresse à l'étranger<RequiredStarComponent class="mr-2" /></label>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            v-model="form.adresseEtranger"
                            type="text"
                            placeholder="Veuillez entrer votre adresse à l'étranger"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- mode hebergement benin -->
                    <b-col
                      v-if="
                        form.paysResidence &&
                          !isBeninResident(form.paysResidence)
                      "
                      md="12"
                    >
                      <b-form-group>
                        <label
                          for=""
                          class="col-form-label col-form-label-md"
                        >Mode d'hébergement au Bénin<RequiredStarComponent class="mr-2" /></label>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-form-radio-group
                            v-model="form.modeHebergementBenin"
                            :options="modesHebergement"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <validation-provider
                          v-if="form.modeHebergementBenin === 'Autres'"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            v-model="form.autreModeHebergementBenin"
                            class="d-inline"
                            type="text"
                            placeholder="Veuillez préciser le mode d'hébergement"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- mode hebergement etranger -->
                    <b-col
                      v-if="
                        form.paysResidence &&
                          isBeninResident(form.paysResidence)
                      "
                      md="12"
                    >
                      <b-form-group>
                        <label
                          for=""
                          class="col-form-label col-form-label-md"
                        >Mode d'hébergement à l'étranger<RequiredStarComponent class="mr-2" /></label>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-form-radio-group
                            v-model="form.modeHebergementEtranger"
                            :options="modesHebergement"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <validation-provider
                          v-if="form.modeHebergementEtranger === 'Autres'"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            v-model="form.autreModeHebergementEtranger"
                            class="d-inline"
                            type="text"
                            placeholder="Veuillez préciser le mode d'hébergement"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- ADRESSE AU BENIN /Address in Benin -->
                    <b-col md="6">
                      <b-form-group>
                        <label
                          for=""
                          class="col-form-label col-form-label-md"
                        >Adresse au Bénin<RequiredStarComponent class="mr-2" /></label>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            v-model="form.adresseBenin"
                            type="text"
                            placeholder="Veuillez entrer votre adresse au Bénin"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- PAYS DE PROVENANCE /Country of origin -->
                    <b-col md="6">
                      <b-form-group>
                        <label
                          for=""
                          class="col-form-label col-form-label-md"
                        >Pays de provenance<RequiredStarComponent class="mr-2" /></label>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <SelectCountriesList
                            v-model="form.paysProvenence"
                            :input-value="form.paysProvenence"
                            :placeholder="'Sélectionnez le pays de provenance'"
                            :remove-countries="['Bénin']"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- DESTINATION (Ville/Pays) -->
                    <b-col
                      v-if="form.destination"
                      md="12"
                    >
                      <div><strong>Destination</strong></div>
                      <b-row class="">
                        <!-- ville -->
                        <b-col md="6">
                          <b-form-group>
                            <label
                              for=""
                              class="col-form-label col-form-label-md"
                            >Ville<RequiredStarComponent class="mr-2" /></label>
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model="form.destination.ville"
                                type="text"
                                placeholder="Veuillez entrer le nom de la ville"
                              />
                              <!-- <v-select
                                  v-model="form.destination.ville"
                                  type="text"
                                  :options="villes"
                                  label="name"
                                /> -->
                              <small class="text-danger">{{
                                errors[0]
                              }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- pays -->
                        <b-col md="6">
                          <b-form-group>
                            <label
                              for=""
                              class="col-form-label col-form-label-md"
                            >Pays<RequiredStarComponent class="mr-2" /></label>
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required"
                            >
                              <SelectCountriesList
                                v-model="form.destination.pays"
                                :input-value="form.destination.pays"
                                :placeholder="'Sélectionnez le pays de destination'"
                              />
                              <small class="text-danger">{{
                                errors[0]
                              }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        
                      </b-row>
                    </b-col>
                    <!-- MOTIF PRINCIPAL DU VOYAGE (Reason for travel)  -->
                    <b-col md="12">
                      <b-form-group>
                        <label
                          for=""
                          class="col-form-label col-form-label-md"
                        >
                          {{ form_frontiere_entree_label_q17_q18(form.paysResidence)[0] }}
                          <RequiredStarComponent class="mr-2" />
                        </label>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-form-radio-group
                            v-model="form.motifVoyage"
                            :options="motifPrincipalVoyage"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <validation-provider
                          v-if="form.motifVoyage === 'Autres'"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            v-model="form.autreMotifVoyage"
                            class="d-inline mt-1"
                            type="text"
                            placeholder="Veuillez préciser le motif du voyage"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- DUREE DE SEJOUR (Length of stay)  -->
                    <b-col md="6">
                      <b-form-group>
                        <label
                          for=""
                          class="col-form-label col-form-label-md"
                        >
                          {{ form_frontiere_entree_label_q17_q18(form.paysResidence)[1] }}
                          <RequiredStarComponent class="mr-2" />
                        </label>
                        <validation-provider
                          v-slot="{ errors }"
                          :rules="{
                            required,
                          }"
                        >
                          <b-form-input
                            v-model="form.dureeSejour"
                            v-digits-only
                            type="number"
                            placeholder="Veuillez préciser la durée de séjour du voyageur"
                            class="form-control"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- CATEGORIE SOCIO-PROfESSIONNELLE/Socio-professional category  -->
                    <b-col md="6">
                      <b-form-group>
                        <label
                          for=""
                          class="col-form-label col-form-label-md"
                        >Catégorie socio-professionnelle<RequiredStarComponent class="mr-2" /></label>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-form-radio-group
                            v-model="form.categorieSocioPro"
                            :options="categorieSocioProList"
                            value-field="value"
                            text-field="text"
                            disabled-field="disabled"
                            class=""
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <validation-provider
                          v-if="form.categorieSocioPro === 'Autres'"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            v-model="form.autreCategorieSocioPro"
                            class="d-inline mt-1"
                            type="text"
                            placeholder="Veuillez préciser la catégorie socio-professionnelle"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- TITRE DE VOYAGE/ Travel document -->
                    <b-col md="12">
                      <div><strong>Titre de voyage</strong></div>
                      <b-row class="">
                        <b-col md="6">
                          <b-form-group>
                            <label
                              for=""
                              class="col-form-label col-form-label-md"
                            >Type</label>
                            <!-- <validation-provider
                              v-slot="{ errors }"
                              rules="required"
                            >
                              
                              <small class="text-danger">{{
                                errors[0]
                              }}</small>
                            </validation-provider> -->
                            <b-form-radio-group
                              v-model="form.titreVoyage.titre"
                              :options="['Passeport', 'CNI', 'Autres']"
                              value-field="value"
                              text-field="text"
                              disabled-field="disabled"
                              class="ml-2"
                            />
                            <b-form-input
                              v-model="form.autreTitreVoyage"
                              class="d-inline"
                              type="text"
                              placeholder="Veuillez préciser le titre de voyage"
                            />
                            <!-- <validation-provider
                              v-if="form.titreVoyage.titre === 'Autres'"
                              v-slot="{ errors }"
                              rules="required"
                            >
                              <small class="text-danger">{{
                                errors[0]
                              }}</small>
                            </validation-provider> -->
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group>
                            <label
                              for=""
                              class="col-form-label col-form-label-md"
                            >Date de délivrance</label>
                            <b-form-input
                              v-model="form.titreVoyage.dateDelivrance"
                              lang="fr-CA"
                              type="date"
                              placeholder="Veuillez préciser la date de délivrance"
                              class="form-control"
                              :max="form.date"
                            />
                            <!-- date_between: [
                                form.titreVoyage.dateDelivrance,
                                null,
                                true,
                              ], -->
                            <!-- :disabled="!form.date" -->
                            <!-- <validation-provider
                              v-slot="{ errors }"
                              :rules="{
                                date_between: [null, form.titreVoyage.dateExpiration, true],
                              }"
                            >
                              
                              <small class="text-danger">{{
                                errors[0]
                              }}</small>
                            </validation-provider> -->
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group>
                            <label
                              for=""
                              class="col-form-label col-form-label-md"
                            >Lieu de délivrance</label>
                            <b-form-input
                              v-model="form.titreVoyage.lieuDelivrance"
                              type="text"
                              placeholder="Veuillez préciser le lieu de délivrance"
                            />
                            <!-- <validation-provider
                              v-slot="{ errors }"
                              rules="required"
                            >
                              
                              <small class="text-danger">{{
                                errors[0]
                              }}</small>
                            </validation-provider> -->
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group>
                            <label
                              for=""
                              class="col-form-label col-form-label-md"
                            >Date d'expiration</label>
                            <!-- <validation-provider
                              v-slot="{ errors }"
                              :rules="{
                                date_between: [
                                  form.titreVoyage.dateDelivrance,
                                  null,
                                  true,
                                ],
                              }"
                            >
                              
                              <small class="text-danger">{{
                                errors[0]
                              }}</small>
                            </validation-provider> -->
                            <b-form-input
                              v-model="form.titreVoyage.dateExpiration"
                              lang="fr-CA"
                              type="date"
                              placeholder="Veuillez préciser la date d'expiration"
                              class="form-control"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <!-- NUMERO DU VEHICULE/ Vehicle number  -->
                    <b-col md="4">
                      <b-form-group
                        label-cols-lg="12"
                        label="Numéro de véhicule (Facultatif)"
                      >
                        <b-form-input
                          v-model="form.numeroVehicule"
                          type="text"
                          placeholder="Veuillez entrer le numéro de véhicule"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- NOM ET PRENOMS DU CONDUCTEUR / full name of the driver  -->
                    <b-col md="4">
                      <b-form-group
                        label-cols-lg="12"
                        label="Nom et prénom du conducteur"
                      >
                        <b-form-input
                          v-model="form.fullnameConducteur"
                          type="text"
                          placeholder="Veuillez entrer le nom et le prénom du conducteur"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- NUMERO DU PERMIS DE CONDUIRE/ Driver's license number -->
                    <b-col md="4">
                      <b-form-group
                        label-cols-lg="12"
                        label="Numéro du permis de conduire (Facultatif)"
                      >
                        <!-- v-digits-only="{ length: 15 }" -->
                        <b-form-input
                          v-model="form.numeroPermisConduire"
                          type="text"
                          placeholder="Veuillez entrer le numéro du permis de conduire"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                        label-cols-lg="12"
                        label="Accompagné de (Nombre d’enfants) (pour une famille en déplacement l’information sera renseignée pour un seul conjoint)"
                      >
                        <b-form-input
                          v-model="form.nombreAccompagnants"
                          v-digits-only="{ length: 10 }"
                          type="number"
                          max=""
                          placeholder="Accompagné de..."
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </div>

            <div class="text-right">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="col-xl-2"
                @click="sendToPreview()"
              >
              Aperçu avant enregistrement
              </b-button>
            </div>
          </b-col>
          </b-row>
        </b-card>
      </div>
    </validation-observer>

    <!-- modal -->
    <b-modal
      :ref="tipsModalRef"
      scrollable
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      title="Créer un formulaire"
      size="lg"
    >
      <b-row>
        <b-col cols="12">
          <b-card>
            <h3 class="text-primary">
              Créer a partir du dernier formulaire enregistré
            </h3>
            <p class="my-1">
              Vous avez la possibilité de récupérer les données du dernier
              formulaire que vous avez enregistré pour remplir ce nouveau
              formulaire.
            </p>
            <b-button
              variant="outline-primary"
              class="float-right"
              @click="fillFormWithLastRecord"
            >
              Continuer
            </b-button>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card>
            <h3 class="text-primary">
              Créer a partir d'un formulaire vierge
            </h3>
            <p class="my-1">
              Vous avez la possibilté de remplir le formulaire de zéro.
            </p>

            <b-button
              variant="outline-primary"
              class="float-right"
              @click="hideModal"
            >
              Continuer
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BCardImg,
  BFormCheckbox,
  BFormRadio,
  BForm,
  BCardText,
  BInputGroupPrepend,
  BInputGroup,
  VBPopover,
  BModal,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { mapActions } from "vuex";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ContentLoader } from "vue-content-loader";
import localstorageService from "@/services/localstorage/localstorage.service";
import TipsModalCreateFromLastRecord from "@/components/TipsModalCreateFromLastRecord.vue";
import utilsService from "@/services/utils/utils.service";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import vSelect from "vue-select";

// imports for validation
// const store = require('store')

// store modules and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import utilsStoreModule from "@/store/utils";
import formulaireStoreModule from "@/store/formulaire";
import SelectCountriesList from "@/components/SelectCountriesList.vue";
import RequiredStarComponent from "@/components/RequiredStarComponent.vue";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    flatPickr,
    BFormCheckbox,
    BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCardImg,
    BCardText,
    BForm,
    BInputGroupPrepend,
    BInputGroup,
    ContentLoader,
    TipsModalCreateFromLastRecord,
    BModal,
    SelectCountriesList,
    RequiredStarComponent,
  },
  directives: {
    Ripple,
    "b-popover": VBPopover,
  },
  mixins: [heightTransition], //
  props: {
    etablissementDetails: null,
  },
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "utils", module: utilsStoreModule },
      { path: "formulaire", module: formulaireStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  data() {
    return {
      count: 0,
      showDesc: false,
      isFillingForm: false,
      formInput: this.$route.params.data,
      lastRecordedForm: null,
      tipsModalRef: new Date().getTime(),
      showCreateFromLastModal: true,
      currentUserId: localstorageService.getUserId(),
      isCheckingForLastForm: true,
      formType: "formstatagence",
      email,
      required,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,

      annees: [],
      mois: [],
      departements: [],
      communes: [],
      arrondissements: [],
      quartiers: [],
      pays: [],
      villes: [],
      typesEts: [
        { text: "Agence de voyage", value: "Agence de voyage" },
        { text: "Tour Operator", value: "Tour Operator" },
        { text: "Agence réceptive", value: "Agence réceptive" },
      ],
      sexeList: [
        { text: "Masculin", value: "Masculin" },
        { text: "Féminin", value: "Féminin" },
      ],
      categorieSocioProList: [
        { text: "Libéral/indépendant", value: "Libéral/indépendant" },
        { text: "Employé/salarié", value: "Employé/salarié" },
        { text: "Retraité", value: "Retraité" },
        { text: "Autres (A préciser)", value: "Autres" },
      ],
      modesHebergement: [
        { text: "Hôtel et assimilés ", value: "Hôtel et assimilés " },
        { text: "Famille/Amis ", value: "Famille/Amis" },
        { text: "Autres", value: "Autres" },
      ],
      motifPrincipalVoyage: [
        {
          text: "Affaires et motifs professionnels",
          value: "Affaires et motifs professionnels",
        },
        {
          text: "Vacances, loisirs et détente",
          value: "Vacances, loisirs et détente",
        },
        {
          text: "Visites à des parents ou des amis",
          value: "Visites à des parents ou des amis",
        },
        { text: "Éducation et formation", value: "Éducation et formation" },
        { text: "Santé et soins médicaux", value: "Santé et soins médicaux" },
        { text: "Religion ou pèlerinage", value: "Religion ou pèlerinage" },
        { text: "Achats", value: "Achats" },
        { text: "Transit", value: "Transit" },
        { text: "Autres (A préciser)", value: "Autres" },
      ],
      agrementOptions: ["non", "oui"],

      form: {
        dateSortie: "",
        reserveAdministration: {
          commissariat: "",
          visa: {
            delivreePar: "",
            numeroVisa: "",
            dateDelivrance: "",
            dureeValidite: {
              annee: "",
              mois: "",
            },
          },
        },
        date: null,
        heure: "",
        lastname: "",
        firstname: "",
        maidenname: "",
        sexe: "",
        dateNaissance: "",
        lieuNaissance: "",
        nationalite: "",
        paysResidence: "",
        adresseEtranger: "",
        adresseBenin: "",
        modeHebergementBenin: "",
        modeHebergementEtranger: "",
        paysProvenence: "",
        destination: {
          ville: "",
          pays: "",
        },
        motifVoyage: "",
        dureeSejour: null,
        categorieSocioPro: "",
        titreVoyage: {
          titre: "",
          dateDelivrance: "",
          lieuDelivrance: "",
          dateExpiration: "",
        },
        numeroVehicule: "",
        fullnameConducteur: "",
        numeroPermisConduire: "",
        nombreAccompagnants: "",

        autreModeHebergementBenin: "",
        autreModeHebergementEtranger: "",
        autreMotifVoyage: "",
        autreCategorieSocioPro: "",
        autreTitreVoyage: "",
      },
      // dureeSejourMois: null,
      // dureeSejourJours: null,

      agrements: [
        { etablissement: "Les Hirondelles", reference: "hg7h878jhjKJ" },
      ],
      invalideAgrement: false,
      mauvaisEtablissement: false,
      invalideIfu: false,

      today: moment().format("YYYY-MM-DD"),
      birthDateAfterEntryDate: false,
    };
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer;
      if (rendererType === "sidebar-left") {
        return "layout-content-renderer-left";
      }
      if (rendererType === "sidebar-left-detached") {
        return "layout-content-renderer-left-detached";
      }
      return "layout-content-renderer-default";
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(_val, _old) {
        const userId = localstorageService.getUserId();
        const etablissementDetails = this.etablissementDetails || {};
        if (this.formInput && this.formInput !== undefined) {
          this.isFillingForm = true;
          this.form = { ...this.formInput };
        } else {
          // this.form = { ...this.form }
        }
      },
    },
    "form.departement": {
      immediate: false,
      deep: true,
      handler(_val, _old) {
        if (this.isFillingForm) return;
        (this.form.commune = ""), (this.communes = []);
        this.loadCommunes();
      },
    },
    "form.paysProvenence": {
      deep: true,
      immediate: false,
      handler(_val, _old) {
        this.control_pays_provenance_pays_destination();
      },
    },
    "form.destination.pays": {
      deep: true,
      immediate: false,
      handler(_val, _old) {
        this.control_pays_provenance_pays_destination();
      },
    },
    "form.commune": {
      immediate: false,
      deep: true,
      handler(_val, _old) {
        if (this.isFillingForm) return;
        (this.form.arrondissement = ""), (this.arrondissements = []);
        this.loadArrondissements();
      },
    },
    "form.heure": {
      immediate: false,
      deep: true,
      handler(_val, _old) {
        if (this.form.date === this.today) {
          const currentHour = moment().format("H:mm");
          if (this.form.heure > currentHour) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "L'heure ne peut pas être supérieur à l'heure actuelle.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            return;
          }
        }
      },
    },
    "form.arrondissement": {
      immediate: false,
      deep: true,
      handler(_val, _old) {
        if (this.isFillingForm) return;
        (this.form.quartier = ""), (this.quartiers = []);
        this.loadNeighborhoods();
      },
    },
    "form.dateNaissance": {
      immediate: false,
      deep: true,
      handler(val, _old) {
        if (val && this.form.date) {
          const myMomentDateEntree = moment(this.form.date, "YYYY-MM-DD");
          const myMomentDateNaissance = moment(val, "YYYY-MM-DD");
          if (myMomentDateNaissance.isAfter(myMomentDateEntree)) {
            this.birthDateAfterEntryDate = true;
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  "La date de naissance ne peut pas être postérieur à la date d'entrée du voyageur",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        }
      },
    },
    "form.dureeSejour": {
      immediate: false,
      deep: true,
      handler(_val, _old) {
        if (this.form.date) {
          const dateEntree = this.form.date
            .split("-")
            .map((e) => parseInt(e, 10));
          dateEntree[1] -= 1;
          const dateEntreeMoment = moment(dateEntree);
          dateEntreeMoment.add(this.form.dureeSejour, "days");
          this.form.dateSortie = dateEntreeMoment.format("YYYY-MM-DD");
        }
      },
    },
    "form.titreVoyage.dateExpiration": {
      immediate: false,
      deep: true,
      handler(val, _old) {
        if (val && this.form.titreVoyage.dateDelivrance) {
          const myMomentDateEntree = moment(
            this.form.titreVoyage.dateDelivrance,
            "YYYY-MM-DD"
          );
          const myMomentDateNaissance = moment(val, "YYYY-MM-DD");
          if (myMomentDateNaissance.isBefore(myMomentDateEntree)) {
            this.birthDateAfterEntryDate = true;
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  "La date d'expiration ne peut pas être anterireur à la date de délivrance du titre de voyage",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        }
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  mounted() {
    // const { countries, communes } = utilsService.currentUserUtils()
    // this.annees = utilsService.getListYears();
    // this.mois = utilsService.getListMonths();
    this.annees = null;
    this.mois = null;
    this.pays = utilsService.getListCountries();
    this.villes = utilsService.getCommunes();
    this.getListTypesEtablissement();
    this.loadDepartments();
    this.loadCommunes();
    this.loadArrondissements();
  },
  methods: {
    ...mapActions("params", {
      fetchAnnees: "fetchAnnees",
    }),
    ...mapActions("formulaire", {
      getLastFormCreatedByUser: "getLastFormCreatedByUser",
    }),
    ...mapActions("utils", {
      checkIFU: "checkIFU",
    }),
    ...mapActions("params", {
      action_loadDepartments: "loadDepartments",
      action_loadCommunes: "loadCommunes",
      action_loadArrondissements: "loadArrondissements",
      action_loadNeighborhoods: "loadNeighborhoods",
    }),
    ...mapActions("etablissements", {
      createEtablissement: "createEtablissement",
      action_getListTypesEtablissement: "getListTypesEtablissement",
    }),
    isBeninResident(country) {
      if (country && country.name) return country.name === "Bénin";
      return country && country === "Bénin";
    },
    loadDepartments() {
      this.action_loadDepartments()
        .then((res) => {
          this.departements = res;
        })
        .catch((_err) => {});
    },

    loadCommunes() {
      this.action_loadCommunes(this.form.departement)
        .then((res) => {
          this.communes = res;
        })
        .catch((_err) => {});
    },
    loadArrondissements() {
      this.action_loadArrondissements(this.form.commune)
        .then((res) => {
          this.arrondissements = res;
        })
        .catch((_err) => {});
    },
    loadNeighborhoods() {
      this.action_loadNeighborhoods(this.form.arrondissement)
        .then((res) => {
          this.quartiers = res;
        })
        .catch((_err) => {});
    },
    getListTypesEtablissement() {
      this.action_getListTypesEtablissement()
        .then((_res) => {
          // this.typesEts = res.data.typeetablissements || [];
        })
        .catch((_err) => {});
    },
    showModal() {
      this.$refs[this.tipsModalRef].show();
    },
    hideModal() {
      this.$refs[this.tipsModalRef].hide();
    },
    fillFormWithLastRecord() {
      this.isFillingForm = true;
      this.form = this.lastRecordedForm;
      setTimeout(() => {
        this.isFillingForm = false;
      }, 1000);
      this.hideModal();
    },
    control_pays_provenance_pays_destination() {
      if (this.form.paysProvenence === this.form.destination.pays) {
        return false;
      }
      return true;
    },
    sendToPreview() {
      console.log("0::: in fonction ++++++++++++++++ ");
      // if( this.birthDateAfterEntryDate){
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'La date de naissance ne peut pas être postérieur à la date d\'entrée du voyageur',
      //       icon: 'AlertTriangleIcon',
      //       variant: 'danger',
      //     },
      //   })
      // }
      // eslint-disable-next-line camelcase
      if (this.form.date === this.today) {
        const currentHour = moment().format("H:mm");
        if (this.form.heure > currentHour) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "L'heure ne peut pas être inférieure à l'heure actuelle.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          return;
        }
      }

      const response_control = this.control_pays_provenance_pays_destination();
      console.log("0___::: ");
      // eslint-disable-next-line camelcase
      if (!response_control) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "Le pays de provenance ne peut pas être le même que le pays des destination.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return;
      }

      const { userData } = utilsService.currentUserUtils();

      const { commissariatAssociated } = userData;
      console.log("commissariatAssociated::: ", commissariatAssociated);

      console.log("0::: ");

      this.$refs.formRules.validate().then(async (success) => {
        if (success) {
          console.log("1::: ");

          if (this.birthDateAfterEntryDate) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  "La date de naissance ne peut pas être postérieur à la date d'entrée du voyageur",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
          const canSend = true;
          console.log("2::: ");

          // clearing data
          const form = {
            ...this.form,
            commissariat: (commissariatAssociated || {})._id,
            // ...(this.form.modeHebergementBenin === 'Autres' && {
            //   modeHebergementBenin: this.form.autreModeHebergementBenin,
            // }),
          };

          console.log("3::: ");
          if (form.qrcode) delete form.qrcode;
          // eslint-disable-next-line no-underscore-dangle
          if (form._id) delete form._id;
          console.log("4::: ");
          if (canSend) {
            this.$emit("onFormSucess", {
              routerParams: { preview: true, data: form },
            });
          }
        } else {
          const errorFirst = this.$el.querySelector(".is-invalid");
          if (errorFirst) {
            // const errorFirstInput = errorFirst.querySelector('label')
            errorFirst.scrollIntoView({ behavior: "instant" });
            errorFirst.focus();
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/themes/bordered-layout.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.descTextCtn {
  .content {
    //   &.hidden{
    //     visibility: hidden;
    //     height: 0;
    //     transition: height 2s linear;
    //   }
    //   visibility: visible;
    //   height: auto;
    //  transition: visibility 2s linear;
    ul {
      list-style-type: none;

      li:before {
        content: "✔️";
        margin-left: -20px;
        margin-right: 10px;
        font-size: 10px;
      }
    }
    &.showLess {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
